import React from 'react'
import TypeWriter from 'typewriter-effect';

export const Descriptors = () => {
  return (
    <TypeWriter
        options = {{
            strings: ['<span> hard worker. </span>',
            '<span> team player. </span>',
            '<span> competitor. </span>',
        '<span>fast learner. '],
            autoStart:true,
            loop:true
        }} />
  )
}
