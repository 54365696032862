import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/esm/Image'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

export const About = ({projects, aboutMe}) => {
  return (
    <Container id='scrollspyAboutMe'>
      <Row>
        <Col sm={8} > <h4>About Me</h4>
            <p className='about-bio-text'>{aboutMe.description}</p>
            <h5>Tech</h5>
            <ul className='row row-cols-3 row-cols-md-3'>
          {" "}
          {aboutMe.tech.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ul>
        </Col>
        <Col sm={4}>
        <Image src='/images/headshotadjusted.png' roundedCircle fluid/></Col>
      
           
        
        
      </Row>
        
        
          
        
    </Container>
  )
}
